import { type ParentProps, Show } from "solid-js";
import { isServer } from "solid-js/web";
import { parse } from "cookie-es";
import { getRequestEvent } from "solid-js/web";
import { HttpStatusCode } from "@solidjs/start";

type Props = ParentProps & {
  link?: string;
  should404: boolean;
};

export default function AuthenticatedLinkGuard(props: Props) {
  const event = getRequestEvent();
  const cookies = () =>
    parse(
      isServer ? (event!.request.headers.get("cookie") ?? "") : document.cookie,
    );

  const drupalSessionCookieName = Object.keys(cookies()).filter(
    (k) =>
      k.startsWith("SESS") ||
      k.startsWith("SSESS") ||
      k.startsWith("DrupalSolidAuth"),
  );

  const isAuth = () => {
    return drupalSessionCookieName.length > 0;
  };

  const canShow = () => {
    return isAuth() || props.should404 === false;
  };

  return (
    <>
      <Show when={canShow()} fallback={<HttpStatusCode code={404} />}>
        <Show when={isAuth()}>
          <div
            class="local-tasks"
            classList={{
              unpublished: props.should404,
            }}
          >
            <a href={props.link}>Modifier</a>
          </div>
        </Show>
        {props.children}
      </Show>
    </>
  );
}
